.app-navbar {
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;

  > div {
    height: 90px;
    display: flex;
    align-items: center;
    transition: height 0.1s ease-in;
  }

  &.scrolling > div {
    height: 50px;
    margin-bottom: 40px;
  }

  .app-nav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 16px;
    margin-left: 82px;
    margin-right: 82px;
    align-self: stretch;

    li {
      position: relative;
      font-size: 14px;
      flex-shrink: 0;
      cursor: pointer;
      padding: 0px 8px;
      height: 100%;

      a {
        height: 100%;
        display: flex;
        align-items: center;
      }

      &:not(:last-child) {
        margin-right: 32px;
      }

      &::after {
        content: '';
        left: 0;
        top: 1px;
        width: 0%;
        height: 100%;
        position: absolute;
        border-bottom: 2px solid #004177;
        transition: width 0.1s ease-in;
        pointer-events: none;
      }

      &:hover {
        font-weight: 600;
        &::after {
          width: 100%;
        }
      }
    }
  }

  .action-button-group {
    flex-shrink: 0;
    margin-left: auto;
    a {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.menu_button {
  cursor: pointer;
}
