.app-logo {
  display: inline-block;
  position: relative;
  transition: all 0.1s ease-in;

  img {
    width: 100%;
    object-fit: contain;
  }
}
