//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #004177;
$secondary: #73c8ae;
$success: #28c76f;
$danger: #ea5455;
$body-color: #191a1c;
$headings-color: #191a1c;
$font-family-sans-serif: 'sukhumvit_set', 'Montserrat', Helvetica, Arial, serif;
$font-family-monospace: 'sukhumvit_set', 'Montserrat', Helvetica, Arial, serif;
