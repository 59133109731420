/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-thin-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-text-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-text-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-semibold-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-medium-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-light-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'sukhumvit_set';
  src: url('../fonts/sukhumvitset-bold-webfont.woff2') format('woff2'),
    url('../fonts/sukhumvitset-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'sukhumvit_set', 'Montserrat', Helvetica, Arial, serif;
}

.nav-item {
  .active {
    .badge.bg-custom-nav-badge {
      background-color: #fff;
    }
  }

  .badge.bg-custom-nav-badge {
    position: relative;
    left: 10px;
    color: #004177;
    background-color: #f4f4f4;
  }
}

.page-item.active .page-link {
  background-color: #73c8ae;
}

.breadcrumb {
  .active.breadcrumb-item {
    color: #191a1c !important;
    a {
      color: #191a1c !important;
    }
  }
}

.pac-container {
  z-index: 9999;
  border-radius: 8px;
  font-family: 'sukhumvit_set', 'Montserrat', Helvetica, Arial, serif;
  .pac-item {
    padding-top: 4px;
  }
  .pac-icon-marker {
    background-position: -50px -161px;
  }
}

.card-transaction.info {
  height: 150px;
  position: relative;
}

.transaction-infoCard {
  position: absolute;
  height: 120px;
  bottom: 0;
  left: 30px;
}

.gap-y-20 {
  gap: 20px 0px;
}

.flex-basis-0 {
  flex-basis: 0;
}

.cover {
  object-fit: cover;
}

.fc .fc-button-primary:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc-toolbar-title {
  color: #004a85 !important;
}

.rdt_TableHead {
  .rdt_TableHeadRow {
    background-color: #f3f2f7 !important;
  }
}