footer {
  width: 100%;
  max-height: calc(100vh - 1000px);
  bottom: 0;
  left: 0;
}

.footer {
  padding: 48px 0;

  .footer_content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      margin-top: 32px;
      max-width: 320px;
      font-weight: 500;
    }
  }

  .footer_headline {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #191a1c;
  }

  .footer_nav {
    margin-top: 32px;
    list-style: none;
    padding-left: 0;

    li {
      cursor: pointer;
      margin-bottom: 16px;

      &:hover {
        font-weight: 700;
      }
    }
  }

  .footer_contact {
    margin-top: 32px;
  }
  .footer_contact_email {
    text-decoration: none;
    color: #191a1c;
    margin-top: 16px;

    a {
      color: #191a1c;
    }
  }

  @media (min-width: 992px) {
    .footer_content {
      align-items: flex-start;
      p {
        text-align: left;
        margin-top: 32px;
        max-width: 218px;
        font-weight: 500;
      }
    }

    .footer_headline {
      text-align: left;
    }
  }
}

.copy-right {
  font-weight: 500;
  padding: 32px 0;
}

.social-contact {
  button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #f7f3f3;
    background-color: transparent;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      background-color: #f4f4f4;
      border-color: #191a1c;
    }
  }
}
